export default {
  label: 'personal',
  sections: [
    [
      [
        {
          name: 'name',
          type: 'string',
          required: true,
        },
      ],
      [
        {
          name: 'email',
          type: 'string',
          required: true,
        },
      ],
      [
        {
          name: 'email_confirm',
          type: 'string',
          required: true,
          validation: (fields) => {
            const email = fields.find((field) => field.name === 'email');
            return email && fields[0].value === email.value;
          },
        },
      ],
      [
        {
          name: 'phone',
          type: 'string',
          required: true,
        },
      ],
      [
        {
          name: 'address',
          type: 'string',
          required: true,
          validation: () => {},
        },
      ],
      [
        {
          name: 'zip',
          type: 'string',
          required: true,
          validation: () => {},
        },
        {
          name: 'state',
          type: 'string',
          required: true,
          validation: () => {},
        },
      ],
      [
        {
          name: 'message',
          type: 'text',
          required: false,
          validate: () => {},
        },
      ],
    ],
  ],
};
