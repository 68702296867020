<script>
import { mapActions, mapState } from 'vuex';
import InfoBox from '@/components/InfoBox.vue';
import Builder from './forms/Builder.vue';
import PersonalBp from './forms/personal';
import CompanyBp from './forms/company';
import CompanySe from './forms/companySe'

const debounce = require('debounce');

export default {
  name: 'CheckoutInformation',

  data() {
    return {
      hasError: false,
      errorMsg: null,
      info: {},
      form_map: {
        personal: PersonalBp,
        company: CompanyBp,
      },
    };
  },

  

  created() {
    this.info = this.customer;
    console.log(this.customer, "custo")

    // Type 1, company in Sweden
    if (this.customer.country.code === "SE") {
      this.form_map.company = CompanySe;
    }else if (this.customer.type === "company" && this.customer.orgnr && this.customer.country.is_eu === 1) {
      // Type 2, company with VAT number in other EU country
      this.form_map.company = CompanyBp;
    }else if(this.customer.type === "company" && !this.customer.orgnr && this.customer.country.is_eu === 0){
       // Type 3, company without VAT number in other EU country
      this.form_map.company = CompanySe;
    }else if(this.customer.type === "company" && this.customer.country.is_eu === 1){
       // Type 4, company outside of the EU
      this.form_map.company = CompanySe;
    }
  },

  computed: {
    ...mapState(['currency']),
    ...mapState('checkout', ['customer']),
  },

  methods: {
    ...mapActions('cart', ['empty']),
    ...mapActions('checkout', ['setCustomerValidated']),

    async submit() {
      if (this.$refs.form.isValid()) {
        if (this.info.email === this.info.email_confirm) {
          this.setCustomerValidated();
          this.nextStep();
        } else {
          this.hasError = true;
          
        }
      }
    },

    updateState: debounce(function updateCustomer() {
      this.$store.commit('checkout/SET_CUSTOMER', this.info);
    }, 500),

    nextStep() {
      this.$router.push({ name: 'orderSummary' });
    },
  },

  components: { FormBuilder: Builder, InfoBox },

   mounted() {
    document.title = this.$t("checkout.headers.information");
  },
};
</script>

<template>
  <div class="my-6 mx-auto max-w-xl">
    <div class="my-3 text-left">
      <p class="text-4xl">{{ $t('checkout.headers.information') }}</p>
    </div>

    <div class="m-auto max-w-xl rounded mb-4">
      <div class="bg-white shadow-md px-8 pt-6 pb-8">
        <form-builder
          @onUpdate="updateState"
          ref="form"
          v-model="info"
          :blueprint="form_map[customer.type]"
        />

        <div class="mb-6 text-left italic">
          <span class="text-xs">
            {{ $t('checkout.form.required_text') }}
          </span>
        </div>

        <div class="md:flex flex-col text-left mb-6" v-if="hasError">
          <InfoBox type="warning" title="Error" :description="errorMsg" />
        </div>
      </div>

      <div class="flex justify-between">
        <localized-link
          :to="{ name: 'checkoutCustomerType' }"
          class="bg-gray-500 hover:bg-gray-600 text-white my-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {{ $t('checkout.button.back') }}
        </localized-link>

        <button
          class="bg-blue-500 hover:bg-blue-700 text-white my-4 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          @click="submit"
        >
          {{ $t('checkout.button.next') }}
        </button>
      </div>
    </div>
  </div>
</template>
